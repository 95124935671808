/** @jsx jsx */
import { css, keyframes } from '@emotion/core';
import { mq } from "../../constants/styleConstants";

const buttonBgScale = keyframes`
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.5);
  }
`;

export const videoStyle = {
  block: css`
    width: 100%;
    height: 56.25vw;
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    position: relative;
    z-index: 1;
    //transform: translateZ(0);
    overflow: hidden;
    background: #000;
  `,
  videoBlock: css`
    //padding: 56.25% 0 0 0;
    height: 100%;
    position:relative;
    background: #000;
  `,
  videoFrame: css`
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
  `,
  videoFrameShow: css`
    visibility: visible;
  `,
  imageBlock: css`
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: #000;
    //transform: translateZ(0);
    img {
      //transform: translateZ(0);
    }
  `,
  imageWrapper: css`
    height: 100%;
  `,
  playButton: css`
    position: absolute;
    width: 12vw;
    height: 12vw;
    background: rgba(255,255,255, 0.47);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background-color 1.2s ease-in-out;
    will-change: background-color;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba(255,255,255,0.20);
      animation: ${buttonBgScale} 4s 4s cubic-bezier(0.33, 1, 0.68, 1) infinite;
      will-change: transform;
    }
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-40%, -50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 2.4vw 0 2.4vw 2.88vw;
      border-color: transparent transparent transparent #000000;
    }
    div:hover > & {
      background: rgba(255,255,255, 0.9);
    }
    ${mq('medium')} {
      width: 8vw;
      height: 8vw;
      &:after {
        border-width: 1.6vw 0 1.6vw 1.92vw;
      }
    }
  `,
}
