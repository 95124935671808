import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import Player from '@vimeo/player';
import { videoStyle } from "./videoStyle";
import Img from "gatsby-image";
import { imageFadeDuration } from "../../constants/componentConstants";

const VideoFullWidth = ({
                          videoId,
                          image,
                          title,
                          delay,
                        }) => {
  const frameRef = useRef(null);
  const imageRef = useRef(null);
  const player = useRef(null);
  const [isImageShow, setIsImageShow] = useState(true);
  const [isVideoShow, setIsVideoShow] = useState(false);
  const [loadVideo, setLoadVideo] = useState(false);

  useEffect(() => {
    const loadTimer = setTimeout(() => {
      setLoadVideo(true);
    }, delay);
    return () => {
      clearTimeout(loadTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  const onClick = () => {
    if (!frameRef.current) {
      return;
    }
    setIsVideoShow(true);
    gsap.to(imageRef.current,
      {
        duration: 600 / 1000,
        ease: "power2.inOut",
        opacity: 0,
        onComplete: function() {
          setIsImageShow(false);
          player.current.play();
        },
      });
  };

  const setPlayer = () => {
    player.current = new Player(frameRef.current);
  }

  const frameStyle = !isVideoShow ? videoStyle.videoFrame : [videoStyle.videoFrame, videoStyle.videoFrameShow];

  return (
    <div css={videoStyle.block}>
      <div css={videoStyle.videoBlock}>
        {
          loadVideo && (
            <iframe
              src={`https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`}
              css={frameStyle} frameBorder="0"
              allow="autoplay; fullscreen" allowFullScreen
              ref={frameRef}
              title={title}
              onLoad={setPlayer}
            />
          )
        }
      </div>
      <script src="https://player.vimeo.com/api/player.js" />
      {
        isImageShow && (
          <div css={videoStyle.imageBlock} onClick={onClick} role="button" ref={imageRef}>
            <Img
              durationFadeIn={imageFadeDuration}
              css={videoStyle.imageWrapper}
              fluid={image.childImageSharp.fluid}
              alt={title}
            />
            <div css={videoStyle.playButton} />
          </div>
        )
      }
    </div>
  );
}
// function areEqual(prevProps, nextProps) {
//   return (prevProps.videoId === nextProps.videoId
//     && prevProps.image === nextProps.image
//     && prevProps.title === nextProps.title);
// }
export default React.memo(VideoFullWidth);
